import React, { useState } from 'react';
import Category from '../category/Category'
import Slider from '../sliders/Slider'
import './homecontainer.css'
import { useSelector } from 'react-redux'
import Brand from '../brands/Brand'
import img_bg from '../../utils/32400.png';
import img_app from '../../utils/app.png';

const HomeContainer = () => {
    const shop = useSelector((state) => state.shop)
    const setting = useSelector(state => (state.setting));
    const [isActive, setActive] = useState(false);
    const toggleClass = () => {
        setActive(!isActive);
    };

    return (

        // elementor-section-height-min-height elementor-section-items-stretch elementor-section-boxed elementor-section-height-default
        <section id="home" className='home-section container home-element section'>
            {/* Slider & Category */}

            {
                (setting.setting.web_settings.is_android_app === "1" || setting.setting.web_settings.is_ios_app === "1") ? 
                <div id="app_mobile" className={isActive ? '': 'app_bar_show'} >
                    <div  onClick={toggleClass}>
                        <img className="img_show_app" src={img_app} />
                    </div>
                    <div className='content_app_mobile'>

                        {setting.setting.web_settings.is_android_app === "1" ?
                        <a href={setting.setting.web_settings.android_app_url} className='download-button'>
                            <img src={setting.setting.web_settings.play_store_logo} alt='google-play'></img>
                        </a>
                        : <></>}
                        {setting.setting.web_settings.is_ios_app === "1" ?
                        <a href={setting.setting.web_settings.ios_app_url} className='download-button'>
                            <img src={setting.setting.web_settings.ios_store_logo} alt='google-play'></img>
                        </a>
                        : <></>}

                    </div>
                </div> : <></>
            }


            <div className='home-container row'>
                <div className="col-md-12 p-0 col-12">
                    <Slider />
                </div>
            </div>


            {shop.shop?.is_category_section_in_homepage ?
                <div className='category_section' style={{ 
                    backgroundImage: 'url('+img_bg+')' 
                  }}>
                    <div className="container">

                        <Category />

                    </div>
                </div>
            :<></>}
            {shop.shop?.is_brand_section_in_homepage ?
                <div className='category_section'>
                    <div className="container">

                        <Brand />

                    </div>
                </div>
            :<></>}



        </section>

    )
}

export default HomeContainer
