// import React from 'react';
import React, { useState, useEffect } from 'react';
import './slider.css';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, Mousewheel, Autoplay, Pagination } from "swiper";
import { MdGTranslate} from "react-icons/md";
import "swiper/css/pagination";
import "swiper/css";
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../loader/Loader';
import { ActionTypes } from '../../model/action-type';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate  } from 'react-router-dom';
import { setSelectedProductId } from '../../utils/manageLocalStorage';
import { setFilterCategory } from '../../model/reducer/productFilterReducer';
import { setSelectedProduct } from '../../model/reducer/selectedProduct';
import api from '../../api/api';
import { setLanguage, setLanguageList } from "../../model/reducer/languageReducer";

import { Dropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IoMenu  } from 'react-icons/io5';
import { setCategory } from '../../model/reducer/categoryReducer';
import { IoTriangle } from "react-icons/io5";
import { IoIosClose } from "react-icons/io";
import img_bg from '../../utils/img-4_1920x.webp';

const Slider = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleChangeLanguage = (id) => {
        api.getSystemLanguage(id, 0)
            .then(response => response.json())
            .then(result => {
                if (result.status === 1) {
                    document.documentElement.dir = result.data.type;
                    // dispatch({ type: ActionTypes.SET_LANGUAGE, payload: result.data });
                    dispatch(setLanguage({ data: result.data }));
                }
            });
    };
    const language = useSelector((state) => (state.language));
    
    const handleImageUrl = (slide) => {
        switch (slide.type) {
            case "default":

                break;
            case "category":
                dispatch(setFilterCategory({ data: slide.type_id }));
                // dispatch({ type: ActionTypes.SET_FILTER_CATEGORY, payload: slide.type_id });
                navigate('/products/');
                break;
            case "product":
                // dispatch({ type: ActionTypes.SET_SELECTED_PRODUCT, payload: slide.type_id });
                dispatch(setSelectedProduct({ data: slide.type_id }));
                setSelectedProductId(slide.type_id);
                navigate('/product/');
                break;

            default:
                break;
        }
    };
    
    const fetchCategory = (id = 0) => {
        api.getCategory(id)
            .then(response => response.json())
            .then(result => {
                if (result.status === 1) {
                    dispatch(setCategory({ data: result.data }));
                    // dispatch({ type: ActionTypes.SET_CATEGORY, payload: result.data });
                }
            })
            .catch(error => console.log("error ", error));
    };
    useEffect(() => {
        fetchCategory();
    }, []);


    const setting = useSelector(state => state.setting);
    const shop = useSelector(state => state.shop);

    const cssmode = useSelector(state => (state.cssmode));
    const languages = useSelector((state) => (state.language));
    const { t } = useTranslation();
    const category = useSelector((state) => (state.category));
    const [isActive, setActive] = useState(false);
    const toggleClass = () => {
        setActive(!isActive);
    };
    const selectCategory = (ctg) => {
        if (ctg.has_child) {
            fetchCategory(ctg.id);
            navigate('/products');
        } else {
            dispatch(setFilterCategory({ data: ctg.id }));
            // dispatch({ type: ActionTypes.SET_FILTER_CATEGORY, payload: ctg.id });
            navigate('/products');
        }
    };
    const placeHolderImage = (e) => {

        e.target.src = setting.setting?.web_logo;
    };
    return (
        <div className='slider '>
            
            <div className="nav_slide">

            <Container>
                <Row>
                    <div style={{position: 'relative'}} className='col-md-4 d-flex justify-content-center align-items-center'>
                        <button className='all_categorys' onClick={toggleClass}  >
                            <span className='btn-menu'>
                            {isActive ? <IoIosClose className='menu_icon' />: <IoMenu className='menu_icon' />}
                                
                            </span>
                            <span className='all_categorys_text' >{t("all_categories")}</span>
                        </button>
                        <div className={isActive ? 'show_category list_categorys': 'hide_category list_categorys'}>
                            <IoTriangle />
                            <div className="list_categorys_overflow">
                                {category.category === null
                                ? (

                                    <Loader background='none' width='fit-content' height='fit-content' />
                                )
                                : (
                                    <ul className='category-list'>
                                        {category.category.map((ctg, index) => (<li key={index}>

                                                <button className='link' onClick={() => {
                                                    selectCategory(ctg);
                                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                                }}>
                                                <div className="d-flex">
                                                    <img onError={placeHolderImage} src={process.env.REACT_APP_API_URL+"/storage/"+ctg.image} alt={ctg.slug} className=
                                                    {` card-img-top `}
                                                    onClick={() => {

                                                    }} />
                                                    <p className='ctg_name' >{ctg.name}</p>
                                                </div>



                                                    
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 d-flex justify-content-center  align-items-center'>
                        <Link to='/about' className={`borderPad ${(cssmode.cssmode === "dark") ? "dark-header-1" : ''}`} > {t('about_us')}</Link>
                        <Link to='/contact' className={`borderPad`} > {t('contact_us')}</Link>
                        <Link to='/faq' className={`borderPad`} >{t('faq')}</Link>
                    </div>
                    <div className='col-md-4 d-flex justify-content-center  align-items-center'>

                    {setting.setting && setting.setting.social_media.length > 0 &&
                        <div className="social-media-icons order-sm-0">
                            <span className="icons_media_icon" >
                                {setting.setting.social_media.map((icon, index) => {
                                    return (
                                        <>
                                            <a key={index} href={icon.link} className='socical-icons'><i className={`${icon.icon} fa-lg`} style={{ color: "#fff" }}></i></a>
                                        </>
                                    );
                                })}
                            </span>
                        </div>
                    }

             

                    </div>
                </Row>
            </Container>
            </div>
            {/* <div className='language-container bg-white' >
                    <MdGTranslate size={24} />
                    <Dropdown>
                        <Dropdown.Toggle>
                            {languages.current_language && languages.current_language.name}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {languages.available_languages && languages.available_languages.map((language, index) => {
                                return (
                                    <Dropdown.Item key={index} onClick={() => { handleChangeLanguage(language.id); }}>{language.name}</Dropdown.Item>
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </div> */}
            {
                shop.shop === null
                    ? (
                        <Loader width='100%' height='500px' screen='full' />
                    )
                    : (
                        <div className="slider__flex ">
                            <div className="slider__images">
                                <Swiper

                                    loop={true}
                                    autoplay={{
                                        delay: 3000,
                                        disableOnInteraction: false,
                                    }}
                                    centeredSlides={false}
                                    // thumbs={{ swiper: imagesNavSlider && !imagesNavSlider.destroyed ? imagesNavSlider : null }}
                                    direction="horizontal"
                                    slidesPerView={1}
                                    spaceBetween={15}
                                    mousewheel={false}

                                    breakpoints={{
                                        0: {
                                            direction: "horizontal"
                                        },
                                        768: {
                                            direction: "horizontal"
                                        }
                                    }}
                                    className="swiper-container2"
                                    modules={[Navigation, Thumbs, Mousewheel, Autoplay, Pagination]}
                                    pagination={{
                                        dynamicBullets: true,
                                    }}
                                    navigation={true}
                                    key={language.current_language && language.current_language.type}
                                >

                                    {shop.shop.sliders.map((sld, index) => {
                                        return (

                                            <SwiperSlide key={index} >
                                                {sld.type === "slider_url" ?
                                                    <a href={sld.slider_url}>
                                                        <div className="slider__image" onClick={() => handleImageUrl(sld)}>
                                                            <img src={sld.image_url} onError={placeHolderImage} alt={sld.type} id='slider-photo' />
                                                        </div>
                                                    </a>
                                                    :
                                                    <div className="slider__image" onClick={() => handleImageUrl(sld)} onError={placeHolderImage}>
                                                        <img src={sld.image_url} alt={sld.type} id='slider-photo' />
                                                    </div>
                                                }
                                            </SwiperSlide>

                                        );
                                    })}




                                </Swiper>
                            </div>



                        </div>
                    )
            }
        </div>
    );
};

export default Slider;
